import React from 'react';
import ReactDOM from 'react-dom';
import './global.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import zhCN from 'antd/lib/locale/zh_CN';
import {ConfigProvider} from 'antd'
import moment from 'moment'
import 'moment/locale/zh-cn';
moment.locale('zh-CN');

ReactDOM.render(
  <ConfigProvider
      locale={zhCN}
      space={{size:16}}
  >
    <React.Fragment>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.Fragment>
  </ConfigProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
