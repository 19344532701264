import { createContext } from 'react';

export const UPDATE_USER_MENUS = 'UPDATE_USER_MENUS';

export const MainContext = createContext({
  state: {},
  dispatch: () => {
  }
});
function getMenuCodes(menus) {
  return (menus || []).reduce((total, item)=> {
    return total.concat(item.menuType + item.menuName, getMenuCodes(item.children))
  }, [])
}
export const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_USER_MENUS:
      return {
        ...state,
        userMenuCodes: getMenuCodes(action.userMenus)
      };
    default:
      return state;
  }
};
