import './App.less';
import { useReducer } from "react";
import { useRoutes } from "react-router-dom";
import routes from './router';
import { MainContext, reducer } from '@/store/main';

function App() {
  const [state, dispatch] = useReducer(reducer, {permissions: ['home']});
  const element = useRoutes(routes);

  return (
    // 全局的数据仓库
    <MainContext.Provider className="App"
        value={{state, dispatch}}
    >
      {element}
    </MainContext.Provider>
  );
}

export default App;
