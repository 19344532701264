import loadable from "loadable-components";
import { Navigate } from "react-router";
import { Outlet } from 'react-router-dom';

const NoFound = loadable(() => import("../components/NoFound/index"));
const BaseLayout = loadable(() => import("../layouts/BaseLayouts"));
const AdPublishEdit = loadable(() => import("../pages/AdPublishEdit/index"));
const Auth = loadable(() => import("../components/Auth"));
const Home = loadable(() => import("../pages/Home/index"));
const Device = loadable(() => import("../pages/Device/Index"));
const DeviceType = loadable(() => import("../pages/Device/Type"));
const DeviceSite = loadable(() => import("../pages/Device/Site"));
const Resources = loadable(() => import("../pages/Resources"));
const Product = loadable(() => import("../pages/Product"));
const ProductAudit = loadable(() => import("../pages/Product/audit"));
const ProductPublish = loadable(() => import("../pages/ProductPublish"));
const ProductType = loadable(() => import("../pages/ProductType"));
const Login = loadable(() => import('../pages/Login'));
const AdPublishPlay = loadable(() => import('../pages/AdPublishPlay'))
const AuthAwait = loadable(() => import('../components/AuthAwait'))
const Package = loadable(() => import("../pages/Package"));

function translatePermissionRoute(route) {
  const { children = [], mate, ...rest } = route;
  const currentRoute = { ...rest, mate };
  if (children) {
    currentRoute.children = children.map(translatePermissionRoute);
  }
  if (mate && mate.auth) {
    currentRoute.element = (
      <Auth {...currentRoute.mate}>{currentRoute.element || <Outlet />}</Auth>
    );
  }
  return currentRoute;
}

export const permissionRoutes = {
  path: "/",
  element: <BaseLayout />,
  children: [
    {
      index: true,
      element: <Navigate to="/home" />
    },
    {
      path: "/home",
      element: <Home />,
      mate: {
        title: "首页",
        name: "home",
        icon: "iconfont-quanjujiance",
        iconActive: require("../assets/images/menu/jqjc.png"),
        isMenu: true
      }
    },
    {
      path: "/product",
      mate: {
        title: '节目管理',
        name: "product",
        icon: 'iconfont-jiemu',
        iconActive: require('../assets/images/menu/jmgl.png'),
        isMenu: true,
        auth: 'M节目管理'
      },
      children: [
        {
          index: true,
          element: <Navigate to="/product/index" />
        },
        {
          path: "/product/resources",
          element: <Resources />,
          mate: {
            title: '素材管理',
            name: "resources",
            isMenu: true,
            auth: 'C素材管理'
          }
        },
        {
          path: "/product/type",
          element: <ProductType />,
          mate: {
            title: '节目分组',
            name: "productType",
            isMenu: true,
            auth: 'C节目分组'
          }
        },
        {
          path: "/product/index",
          element: <Product />,
          mate: {
            title: '节目管理',
            name: "productIndex",
            isMenu: true,
            auth: 'C节目管理'
          }
        },
        {
          path: "/product/audit",
          element: <ProductAudit />,
          mate: {
            title: '节目审核',
            name: "productAudit",
            isMenu: true,
            auth: 'C节目审核'
          }
        },
        {
          path: "/product/publish",
          element: <ProductPublish />,
          mate: {
            title: '节目发布',
            name: "productPublish",
            isMenu: true,
            auth: 'C节目发布'
          }
        }
      ]
    },
    {
      path: "/device",
      mate: {
        title: '设备管理',
        name: "device",
        icon: 'iconfont-shebei',
        iconActive: require('../assets/images/menu/sbgl.png'),
        isMenu: true,
        auth: 'M设备管理'
      },
      children: [
        {
          index: true,
          element: <Navigate to="/device/index" />
        },
        {
          path: "/device/type",
          element: <DeviceType />,
          mate: {
            title: '设备类型',
            name: "deviceType",
            isMenu: true,
            auth: 'C设备类型'
          }
        },
        {
          path: "/device/site",
          element: <DeviceSite />,
          mate: {
            title: '安装位置',
            name: "deviceSite",
            isMenu: true,
            auth: 'C安装位置'
          }
        },
        {
          path: "/device/index",
          element: <Device />,
          mate: {
            title: '设备管理',
            name: "deviceIndex",
            isMenu: true,
            auth: 'C设备管理'
          }
        }
      ]
    },
    {
      path: "/systemConfig",
      mate: {
        title: '系统管理',
        name: "systemConfig",
        icon: 'iconfont-xitong',
        iconActive: require('../assets/images/menu/xtgl.png'),
        isMenu: true,
        auth: 'M系统管理'
      },
      children: [
        {
          index: true,
          element: <Navigate to="/systemConfig/package" />
        },
        {
          path: "/systemConfig/package",
          element: <Package />,
          mate: {
            title: '升级包管理',
            name: "package",
            isMenu: true,
            auth: 'C升级包管理'
          }
        }
      ]
    }
  ]
};

const routes = [
  translatePermissionRoute(permissionRoutes),
  {
    path: "/login",
    name: "login",
    element: <Login />
  },
  {
    path: "/ad-publish-edit/:id",
    element: <AuthAwait><AdPublishEdit /></AuthAwait>,
    mate: {
      title: '节目编辑',
      name: "AdPublishEdit"
    }
  },
  {
    path: "/ad-publish-play",
    element: <AdPublishPlay />,
    mate: {
      title: '节目播放',
      name: "AdPublish",
      isMenu: false
    }
  },
  { path: "*", element: <NoFound /> }
];

const translateRoute = (routesList, route) => {
  const { children = [], ...rest } = route;
  return [...routesList, { ...rest }, ...children.reduce(translateRoute, [])];
};

export const routesList = translateRoute([], permissionRoutes);

export default routes;
